import TravelMapService from './service';
import * as elchspuckeTuicomService from "../../elchspucke-tuicom.service";
import {
    isTravelPeriodWithinCampaignTravelPeriod,
    isValidCampaignDuration
} from '../../campaign/2.1.2/service';

if (window.tuiCottonBall) {
    window.tuiCottonBall.cuddleMeIf('travel-map', '*', 'track mapbutton click', (data) => {
        elchspuckeTuicomService.trackEvent('feature', 'map', 'open', data.variant);
    });
}

function getAvailableCampaign(ibeResultData, ibeSearchData, campaignData) {
    const campaignObject = {
        campaignName: false
    };
    const now = new Date();
    const searchScope = ibeResultData.searchScope;
    const travelPeriod = {
        duration: ibeSearchData.filters.duration,
        startDate: ibeSearchData.filters.startDate,
        endDate: ibeSearchData.filters.endDate
    };
    const childAges = [];
    ibeSearchData.filters.travellers.forEach(room => childAges.push(room.children))

    if (elchspuckeTuicomService.getPageLanguage() !== 'de' || ibeResultData.stepNumber !== 2) {
        return campaignObject;
    }

    let allAvailableCampaigns = campaignData.filter(campaign => {
        const configuration = campaign.campaignConfiguration;
        const campaignStart = new Date(configuration.campaignPeriod.startDate);
        const campaignEnd = new Date(configuration.campaignPeriod.endDate);
        const minNumberOfChildren = configuration.offersSpecification.travellers.children.minimumCount || 0;
        const minChildAge = configuration.offersSpecification.travellers.children.minimumAge || 0;
        const maxChildAge = configuration.offersSpecification.travellers.children.maximumAge || 17;
        
        const validChildAges = childAges.filter(age => minChildAge <= age && maxChildAge >= age);

        return (
            configuration.active &&
            configuration.showMap &&
            configuration.priority &&
            minNumberOfChildren <= validChildAges.length &&
            now > campaignStart &&
            now < campaignEnd &&
            isTravelPeriodWithinCampaignTravelPeriod(travelPeriod, configuration.offersSpecification) &&
            isValidCampaignDuration(travelPeriod, configuration.offersSpecification) &&
            TravelMapService.isConfiguredForSearchScope(searchScope, configuration.offersSpecification) &&
            TravelMapService.showMapInRegion(ibeResultData.response.currentRegion, searchScope, configuration.destinations)
        );
    });

    allAvailableCampaigns = allAvailableCampaigns.sort((a, b) => a.campaignConfiguration.priority - b.campaignConfiguration.priority);

    if (allAvailableCampaigns.length) {
        return allAvailableCampaigns[0];
    }

    return campaignObject;
}

function checkIfDiscoveryMapButtonShouldBeReplaced(data) {

    if (data.stepNumber !== 4) {
        return false;
    }

    var discoveryMapCookie = elchspuckeTuicomService.getCookie('_vis_opt_exp_307_combiXX');
    return discoveryMapCookie === '2' || discoveryMapCookie === '3';
}

function getEventData(data, ibeSearchData) {
    function cb(component, scope, event, url) {
        if (url.indexOf('/') > 0) {
            url = '/' + url;
        }
        if (typeof data.processLink === 'function') {
            data.processLink(window.location.origin + url + '&contentid=travel-map_' + data.mapScope);
        }
    }

    function getFilters(data, ibeSearchData) {
        // https://jira.tuigroup.com/browse/BUG-16880 quick fix: not use ibeSearchData
        if (typeof ibeSearchData === 'undefined' || !ibeSearchData.hasOwnProperty('thisIsNotAProperty_BUG-16880') ) {
            return {
                adults: data.queryParameters.adults,
                startDate: new Date(data.queryParameters.startDate),
                endDate: new Date(data.queryParameters.endDate),
                duration: data.queryParameters.duration,
                searchScope: data.queryParameters.searchScope
            };
        } else {
            return ibeSearchData.filters;
        }
    }

    return {
        callback: cb,
        destination: {
            giataId: data.hotelInformation.giataCode,
            name: data.hotelInformation.hotelName
        },
        filters: getFilters(data, ibeSearchData)
    };

}

function changeMapButton() {
    var vwoCookieValue = elchspuckeTuicomService.getCookie('_vis_opt_exp_307_combiXX');

    // neuen Karte Button erstellen
    var newMapButton = document.createElement('button');
    newMapButton.classList.add('u-p-r1');
    newMapButton.classList.add('u-p-l1');
    newMapButton.classList.add('btn');
    newMapButton.classList.add('btn-white');
    newMapButton.classList.add('btn-white--tdb');
    newMapButton.classList.add('btn-block');

    //Button onclick listener with CB event
    newMapButton.addEventListener("click", function () {
        window.tuiCottonBall.broadcast('travel-map', 'create discoveryMap modal', {});
    });

    // Button Text erstellen
    var textnode = document.createTextNode(" Karte");

    // Map Icon erstellen
    var mapIcon = document.createElement('i');
    mapIcon.classList.add('icon');
    mapIcon.classList.add('icon--location-fill');

    // Icon und Text appenden
    newMapButton.appendChild(mapIcon);
    newMapButton.appendChild(textnode);

    if(vwoCookieValue === 'notInUseForThisTest') {
        var badgeSpan = document.createElement('span');
        badgeSpan.style.fontSize = '12px';
        badgeSpan.style.fontSize = '1.2rem';
        badgeSpan.style.position = 'relative';
        badgeSpan.style.height = '20px';
        badgeSpan.style.color = '#fff';
        badgeSpan.style.backgroundColor = '#d40e14';
        badgeSpan.style.borderRadius = '.53em';
        badgeSpan.style.marginLeft = '3px';
        badgeSpan.style.padding = '2px 6px';

        var badgeText = document.createElement('span');
        badgeText.innerText = 'NEU';

        badgeSpan.appendChild(badgeText);

        newMapButton.appendChild(badgeSpan);
    }


    // Original Karte Button suchen
    var tuiTitle = document.getElementById('tuiTitle');
    var mapButton = tuiTitle.getElementsByClassName('usp-g__scl-md')[0].getElementsByClassName('row')[0].firstElementChild.firstElementChild;

    // Original mit neuem Button tauschen
    mapButton.parentNode.replaceChild(newMapButton, mapButton)
}

export default function travelMap(config) {
    const tenant = config.getTenant();

    if (window.tuiCottonBall) {
        window.tuiCottonBall.cuddleMeIf('travel-map', '*', 'IBE search result rendered.', (component, scope, event, ibeResultData) => {
            window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (a, b, c, ibeSearchData) => {
                window.tuiCottonBall.broadcast('campaign', 'Call me back with your campaign configuration.', campaignData => {

                    let travelMapNode = document.querySelector('.tui-component.tui-component-travel-map');
                    if (travelMapNode) {
                        elchspuckeTuicomService.removeNode(travelMapNode);
                    }

                    const campaginObject = getAvailableCampaign(ibeResultData, ibeSearchData, campaignData);

                    if (campaginObject.campaignName) {
                        TravelMapService.insertTravelMap(tenant, campaginObject);
                    }

                });
            });
        });

        window.tuiCottonBall.cuddleMeIf('travel-map', '*', 'Finished initializing the angular app.', (component, scope, event, data) => {
            if (checkIfDiscoveryMapButtonShouldBeReplaced(data)) {
                // dann Button getauscht werden
                changeMapButton();
            }
        });

        window.tuiCottonBall.cuddleMeIf('travel-map', '*', 'create discoveryMap modal', () => {
            TravelMapService.insertDiscoveryMap(tenant);
        });

        window.tuiCottonBall.cuddleMeIf('travel-map', '*', 'generate PT4 Link.', (component, scope, event, data) => {
            window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (a, b, c, ibeSearchData) => {
                window.tuiCottonBall.broadcast('IBE', 'generate URL', getEventData(data, ibeSearchData));
            });
        });
    }

    return 'travel-map';
}
