import {insertAsFirstChildIfExists, getPageLanguage} from '../../elchspucke-tuicom.service';
import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service';

export default class service {

    static insertDiscoveryMap(tenant) {
        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (a, b, c, ibeSearchData) => {

            var discoveryMap = document.createElement('div');
            discoveryMap.classList.add('tui-component', 'tui-component-travel-map');

            discoveryMap.setAttribute('data-tenant', tenant);
            discoveryMap.setAttribute('data-scope', 'discovery-1');
            discoveryMap.setAttribute('data-mapselector', 'false');
            discoveryMap.setAttribute('data-showpoi', 'false');
            discoveryMap.setAttribute('data-infowindowtype', 'sidebar-right');
            discoveryMap.setAttribute('data-lang', 'de-DE');
            discoveryMap.setAttribute('data-currency', 'EUR');
            discoveryMap.setAttribute('data-brand', 'tui');
            discoveryMap.setAttribute('data-label', 'false');
            discoveryMap.setAttribute('data-giata', ibeSearchData.hotel.giata);
            discoveryMap.setAttribute('data-season', 'S');
            discoveryMap.setAttribute('data-seasonYear', '2019');
            discoveryMap.setAttribute('data-polyline', 'false');
            discoveryMap.setAttribute('data-morphingBanner', 'false');
            discoveryMap.setAttribute('data-discoveryMap', 'true');


            var cookie = elchspuckeTuicomService.getCookie('_vis_opt_exp_307_combiXX');

            if (cookie === '2') {
                discoveryMap.setAttribute('data-loggingLabel', 'pickergallery');
                discoveryMap.setAttribute('data-pickerGallery', 'true');
            } else if (cookie === '3') {
                discoveryMap.setAttribute('data-loggingLabel', 'no-pickergallery');
                discoveryMap.setAttribute('data-pickerGallery', 'false');
            }

            function removeModal() {
                // find the modal and hid if it exists
                document.body.classList.remove('tm-modal-open');
                const modal = document.querySelector('.tm-modal');
                if (modal) {
                    modal.style.display = 'none';
                }
            }

            function renderModal(element) {
                let modal = document.querySelector('.tm-modal');
                let child = document.querySelector('.tm-child');
                let mapComponent = document.querySelector('.tm-modal .tui-component-travel-map');
                if (modal === null) {
                    // create the background modal div
                    modal = document.createElement('div');
                    modal.classList.add('tm-modal');
                    document.body.appendChild(modal);
                }
                if (child === null) {
                    // create the inner modal div with appended argument
                    child = document.createElement('div');
                    child.classList.add('tm-child');
                    modal.appendChild(child);
                }
                if (mapComponent === null) {
                    child.appendChild(element);

                    const closeBtn = document.createElement('button');
                    closeBtn.classList.add('tm-close-btn');
                    let x = document.createTextNode("X");
                    closeBtn.appendChild(x);
                    // remove modal if background clicked
                    closeBtn.addEventListener('click', event => {
                        if (event.target.className === 'tm-close-btn') {
                            removeModal()
                        }
                    });

                    let existingHeader = document.querySelector('.tm-header');

                    if(existingHeader !== null) {
                        existingHeader.parentNode.removeChild(existingHeader);
                    }

                    const header = document.createElement('div');
                    header.classList.add('tm-header');
                    let h = document.createElement("H1");
                    let t = document.createTextNode(ibeSearchData.hotel.name);
                    h.appendChild(t);
                    header.appendChild(h);
                    header.appendChild(closeBtn);
                    modal.appendChild(header);

                    window.tuiCottonBall.broadcast('cotton-ball', 'New TUI components.', {});
                    window.tuiCottonBall.broadcast('travel-map', 'hit me baby one more time.', {});
                }
                document.body.classList.add('tm-modal-open');
                modal.style.display = 'block';

            }

            renderModal(discoveryMap);

        });
    }

    static insertTravelMap(tenant, campaignObject) {
        const getCampaignLabel = (lang, languages) => {
            return languages.filter(f => f.shortName === lang)[0].translations.label;
        };

        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (a, b, c, data) => {
            const tuiComponentTravelMap = document.querySelector('div.tui-component-travel-map');
            let label = getCampaignLabel(getPageLanguage(), campaignObject.campaignConfiguration.languages);
            const mapSectionString = this.generateMapSectionString(data, label);

            if (tuiComponentTravelMap === null) {
                const tuiResult = document.querySelector('section#tuiResult');
                const travelMapDiv = document.createElement('div');
                const lang = getPageLanguage();

                travelMapDiv.classList.add('tui-component', 'tui-component-travel-map');
                travelMapDiv.setAttribute('data-tenant', tenant);
                travelMapDiv.setAttribute('data-scope', `${campaignObject.campaignName}-${campaignObject.campaignConfiguration.campaignPeriod.startDate}`);
                travelMapDiv.setAttribute('data-mapselector', 'false');
                travelMapDiv.setAttribute('data-showpoi', 'false');
                travelMapDiv.setAttribute('data-infowindowtype', 'sidebar-right');
                travelMapDiv.setAttribute('data-lang', lang);
                travelMapDiv.setAttribute('data-currency', 'EUR');
                travelMapDiv.setAttribute('data-brand', 'tui');
                travelMapDiv.setAttribute('data-label', 'false');
                travelMapDiv.setAttribute('data-banner_headline', label);
                travelMapDiv.setAttribute('data-banner_cta_text', 'Hotels auf Karte anzeigen');
                travelMapDiv.setAttribute('data-season', 'S');
                travelMapDiv.setAttribute('data-seasonYear', '2021');
                travelMapDiv.setAttribute('data-polyline', 'false');
                travelMapDiv.setAttribute('data-morphingBanner', 'true');
                travelMapDiv.setAttribute('data-campaign', `campaign-${campaignObject.campaignName}`);
                travelMapDiv.setAttribute('data-mapSection', mapSectionString);
                travelMapDiv.setAttribute('data-searchScope', data.filters.searchScope);
                insertAsFirstChildIfExists(travelMapDiv, tuiResult);

                window.tuiCottonBall.broadcast('travel-map', 'hit me baby one more time.', {});
                window.tuiCottonBall.broadcast('cotton-ball', 'New TUI components.', {});
            }
        });

    }

    static generateMapSectionString(ibeState, label) {
        let countryId = null;
        let name = null;
        let mapSectionString = '';

        if (ibeState.hasOwnProperty('country') && ibeState.country !== null && ibeState.country.id && ibeState.country.name !== null) {
            countryId = ibeState.country.id;
            name = ibeState.country.name;
            mapSectionString = countryId + '|' + label + ' ' + name;
        }

        return mapSectionString

    }

    static showMapInRegion(currentRegion, searchScope, destinations) {

        let regionId;

        if (currentRegion) {
            regionId = currentRegion.countryId;
        }

        if (typeof regionId === 'undefined') {
            return true;
        } else {
            return destinations
                .filter(destination => destination.searchScopes.indexOf(searchScope) >= 0)
                .filter(destination => destination.id === regionId)
                .length > 0;
        }
    }

    static buildCampaignData(data, campaign) {
        window.tuiCottonBall.pleaseTakeCareOf('travel-map', 'window', 'Here is your campaign data.', {data, campaign});
    }

    static isConfiguredForSearchScope(searchScope, campaignOffersSpecification) {
        if (!searchScope || typeof searchScope !== 'string' ||
            !campaignOffersSpecification || typeof campaignOffersSpecification !== 'object') {
            return false;
        }
        return (
            !campaignOffersSpecification.searchType ||
            campaignOffersSpecification.searchType === searchScope
        );
    }

}
