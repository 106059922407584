import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service';
import { pageIs } from '../../util';
import * as service from './service';
const linkBuilder = require('../../link-builder.service').default;

let allHotels = [];
let hotelData = {};

export default function (config) {
    window.tuiCottonBall.subscribe('favorites', 'window', 'Add a hotel to favorites.', (c, s, e, hotelCardsData) =>
        service.addHotel(hotelCardsData, config.getTenant())
    );
    window.tuiCottonBall.subscribe('Hotellist', '*', 'Someone touched my heart.', (c, s, e, hotelCardsData) => {
        if (allHotels.length > 0) {
            hotelData = service.getHotel(hotelCardsData.giataId, allHotels);
        }
        service.addHotel(hotelCardsData, config.getTenant())
    });

    window.tuiCottonBall.subscribe('Hotellist', '*', 'Someone broke my heart.', service.removeHotel);
    window.tuiCottonBall.subscribe('favorites', '*', 'Someone broke my heart.', service.removeHotel);

    window.addEventListener('tui-favorites.FavoritesListsChanged', (event) => {
        try {
            const lists = JSON.parse(event.detail.lists);
            const favoritesEntries = [];

            if (Array.isArray(lists)) {
                lists.forEach((list) => {
                    favoritesEntries.push(...list.entries);
                });
            }

            window.tuiCottonBall.broadcast('Hotellist', 'I am falling in love with someone else.', favoritesEntries);
            window.tuiCottonBall.broadcast(
                'hotel-offer-cards',
                'I am falling in love with someone else.',
                favoritesEntries
            );
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.warn('Could not parse favorites lists');
        }
    });

    document.addEventListener('tui-favorites-popup.CreateFavoriteList', (event) => {
        service.openFavoritesList(config.getTenant(), event.detail.listId);
    });

    document.addEventListener('tui-favorites-popup.OpenFavoritesList', (event) => {
        elchspuckeTuicomService.trackEvent('feature', 'savesearch', 'goto', 'Hotel=' + event.detail.numberOfEntries);
        service.openFavoritesList(config.getTenant(), event.detail.listId);
    });

    document.addEventListener('tui-favorites-heart.OpenFavoritesList', (event) => {
        elchspuckeTuicomService.trackEvent('feature', 'savesearch', 'goto', 'Hotel=' + event.detail.numberOfEntries);
        service.openFavoritesList(config.getTenant(), event.detail.listId);
    });

    document.addEventListener('tui-favorites-heart.OpenFavorites', () => {
        const numberOfSavedLists = window.localStorage.getItem('tui-favorites-lists-ids')?.split(',').length || 0;
        elchspuckeTuicomService.trackEvent('feature', 'savesearch', 'goto', 'Hotel=' + numberOfSavedLists);
        service.openFavorites(config.getTenant());
    });

    window.tuiCottonBall.subscribeToAllScopes(
        'favorites',
        'IBE search result rendered.',
        (component, scope, event, data) => {
            if (data.stepNumber === 3) {
                allHotels = service.getHotelData(data?.response?.hotels);
            } else if (data.stepNumber === 4) {
                hotelData.hotel_name = data?.response?.hotel?.name;
                hotelData.hotel_productcode = data?.response?.offers[0]?.productCode;
                hotelData.destination_city = data?.response?.hotel?.city;
                hotelData.destination_country = data?.response?.country?.name;
                hotelData.destination_region = data?.response?.region?.name;
                hotelData.product_price = data?.response?.offers[0]?.price?.total;
                hotelData.days_travel_duration = data?.response?.offers[0]?.travelPeriod?.hotelDuration;
                hotelData.date_departure = data?.response?.offers[0]?.travelPeriod?.travelStartDate;
                hotelData.giata = data?.response?.hotel?.giataId;
            }
        }
    );

    // tracking
    document.addEventListener('tui-favorites-popup.FavoritesListsCreated', (event) => {
        service.trackEvent(
            'feature', 
            'savesearch', 
            'create_list', 
            event.detail.listName,
            hotelData.hotel_name,
            hotelData.hotel_productcode,
            hotelData.destination_city,
            hotelData.destination_country,
            hotelData.destination_region,
            hotelData.product_price,
            hotelData.days_travel_duration,
            hotelData.date_departure
        );    
    });

    document.addEventListener('tui-favorites-popup.HotelAdded', (event) => {
        elchspuckeTuicomService.trackEvent('feature', 'savesearch', 'open', event.detail.giataId);
    });

    document.addEventListener('tui-favorites-popup.HotelRemoved', (event) => {
        elchspuckeTuicomService.trackEvent('feature', 'savesearch', 'remove', 'Hotel=' + event.detail.numberOfEntries);
    });

    document.addEventListener('tui-favorites-popup.FavoritesListsChanged', (event) => {
        const destinationCode = hotelData.hotel_productcode.match(/[a-zA-Z]+|[0-9]+/g);
        let destinationObj = {
            giataId: hotelData.giata,
            name: hotelData.hotel_name
        };

        const protocol = window.location.protocol;
        const host = window.location.hostname;
        const urlString = linkBuilder.generateUrl(destinationObj, {});
        const queryString = window.location.search;
        const url = protocol + '//' + host + urlString + queryString;

        service.trackEvent(
            'feature',
            'savesearch',
            'change_list',
            'Hotel=' + event.detail.numberOfEntries,
            hotelData.hotel_name,
            hotelData.hotel_productcode,
            hotelData.destination_city,
            hotelData.destination_country,
            hotelData.destination_region,
            hotelData.product_price,
            hotelData.days_travel_duration,
            hotelData.date_departure,
            destinationCode[0],
            url
        );
    });

    document.addEventListener('tui-favorites.TriggerLogin', () => {
        window.tuiCottonBall.broadcast('login', 'Open login overlay.', {});
    });

    // inject
    if (!pageIs.BU1() && !pageIs.BU3()) {
        service.injectFavorites(config.getAWSCdnUrl(), config.getTenant());
    }

    return 'favorites';
}
