import * as ibeMappingService from "../../ibe-mapping.service";
import * as elchspuckeService from "../../elchspucke-tuicom.service";
import * as HotelOfferCardsService from "../../hotel-offer-cards/1.6.0/service";
import { hasDateOverlap, formatDate } from "../../util";
import * as BannerService from "../../banner/1.0.3/service";

const notManuallyTurnedOff = (campaignConfiguration) => {
    /* Turn off campaign manually */
    const searchParams = window.location.search;
    const campaignOff = searchParams.indexOf(campaignConfiguration.campaignName + '-campaign-banner=false') !== -1;
    return !campaignOff;
};

const findMatchingBannerConfiguration = (languageFiles, language, searchType, pageNumber, bannerPosition, stringForAttendingDestination, stringForAttendingHotel) => {

    const configurationForGivenLanguage = languageFiles.find((languageFile) =>
        languageFile.shortName === language &&
        languageFile.translations &&
        Array.isArray(languageFile.translations.banner)
    );
    if (!configurationForGivenLanguage) {
        return false;
    }

    const configurationForGivenSearchType = configurationForGivenLanguage.translations.banner.find((bannerObject) =>
        typeof bannerObject.searchType === 'string' &&
        bannerObject.searchType.toUpperCase() === searchType.toUpperCase() &&
        Array.isArray(bannerObject.pages)
    );
    if (!configurationForGivenSearchType) {
        return false;
    }

    const configurationForGivenPageNumber = configurationForGivenSearchType.pages.find((page) =>
        page.pageName === pageNumber &&
        Array.isArray(page.bannerTypes)
    );
    if (!configurationForGivenPageNumber) {
        return false;
    }

    const filteredBannerTypeForBannerPosition = configurationForGivenPageNumber.bannerTypes.filter((bannerType) =>
        bannerType.configuration &&
        bannerType.position === bannerPosition &&
        bannerType.targetPageName && typeof bannerType.targetPageName === 'string');

    if ( filteredBannerTypeForBannerPosition.length === 0 ) {
        return false;
    }

    const matchingBannerTypeForVisibleFor = filteredBannerTypeForBannerPosition.find((bannerType) =>
        bannerType.visibleFor === undefined ||
        bannerType.visibleFor === '' ||
        bannerType.visibleFor === stringForAttendingDestination ||
        bannerType.visibleFor === stringForAttendingHotel
    );

    if ( !matchingBannerTypeForVisibleFor ) {
        return false;
    }

    return {
        bagVariant: configurationForGivenLanguage.translations.bagVariant,
        language,
        searchType,
        pageNumber,
        bannerPosition,
        configurationForGivenBannerType: matchingBannerTypeForVisibleFor
    };
};

const hasNeededProperties = (campaignConfiguration) => {
    return campaignConfiguration.campaignConfiguration &&
        Array.isArray(campaignConfiguration.campaignConfiguration.languages);
};

const getBannerPosition = (element) => {
    const prefix = 'tui-campaign-banner-';
    const splitResult = element.id.split(prefix);
    if (splitResult.length === 2) {
        return splitResult[1];
    } else {
        return element.id;
    }
};

const isValueContainedInList = (list, value) => {
    return list.indexOf(value) !== -1;
};

const getEventScope = (campaignConfiguration) => campaignConfiguration.campaignName;

const getEventScopes = (campaignConfigurations) => {
    if (!Array.isArray(campaignConfigurations)) {
        return false;
    }

    return campaignConfigurations.map(getEventScope);
};

const getStringForAttendingDestination = (destinations, destinationId, searchType) => {

    if (!destinations || !Array.isArray(destinations) || (destinations.length === 0) || typeof destinationId !== 'string') {
        return 'notAttendingDestination';
    }

    const matchingDestination = destinations.find(
        (destination) =>
            destination.id &&
            destination.id === parseInt(destinationId) &&
            isValueContainedInList(destination.searchScopes, searchType.toUpperCase())
    );

    return matchingDestination ? 'attendingDestination' : 'notAttendingDestination';
};

const getStringForAttendingHotel = (bannerDataset, hotels) => {

    if (typeof bannerDataset.searchScope !== 'string' || !bannerDataset.giataCode || bannerDataset.pageNumber !== '4' ||
        !Array.isArray(hotels) || hotels.length === 0) {
        return 'notAttendingHotel';
    }

    const giataCode = parseInt(bannerDataset.giataCode);

    if (isNaN(giataCode)) {
        return 'notAttendingHotel';
    }

    const searchType = bannerDataset.searchScope.toUpperCase();

    const matchingHotel = hotels.find(
        (hotel) =>
            hotel.giataId &&
            hotel.giataId === giataCode &&
            isValueContainedInList(hotel.searchScopes, searchType)
    );

    return matchingHotel ? 'attendingHotel' : 'notAttendingHotel';
};

const getDestinationId = (ibeState) => {
    if (ibeState.region && ibeState.region.id && typeof ibeState.region.id === 'number') {
        return ibeState.region.id;
    } else if (ibeState.country && ibeState.country.id && typeof ibeState.country.id === 'number') {
        return ibeState.country.id;
    } else if (ibeState.filters.destination && ibeState.filters.destination.regionId && typeof ibeState.filters.destination.regionId === 'number') {
        return ibeState.filters.destination.regionId;
    }

    return false;
};

const isTravelPeriodOutsideCampaignTravelPeriod = (travelPeriod, campaignTravelPeriod) => {

    if (invalidTravelPeriodCheckParameters(travelPeriod, campaignTravelPeriod))
        return false;

    let campaignTravelStartDate = new Date(campaignTravelPeriod.startDate);
    let campaignTravelEndDate = new Date(campaignTravelPeriod.endDate);

    return (travelPeriod.endDate.getTime() < campaignTravelStartDate.getTime() ||
        travelPeriod.startDate.getTime() > campaignTravelEndDate.getTime());
};

const invalidTravelPeriodCheckParameters = (travelPeriod, campaignTravelPeriod) => {
    return (typeof travelPeriod.startDate.getTime !== 'function' || typeof travelPeriod.endDate.getTime !== 'function' ||
        typeof campaignTravelPeriod.startDate !== 'string' || typeof campaignTravelPeriod.endDate !== 'string');

};

const generateBannerId = (campaignName, configurationForGivenBannerType, bannerConfiguration, visibleFor) => {
    let generatedBannerId = campaignName + '_' + bannerConfiguration.searchType.toUpperCase() + '_' + bannerConfiguration.pageNumber +  '_' + bannerConfiguration.bannerPosition;
    const bannerId = visibleFor === '' ? generatedBannerId : generatedBannerId + '_' + visibleFor;

    return configurationForGivenBannerType.bannerId || bannerId;
};

const shouldBannerAlwaysBeShwon = (bannerConfig) => {
    //no value in currentBannerConfig.visibleIf means banner should always be shown
    return (!bannerConfig.visibleIf || !(bannerConfig.visibleIf instanceof Array) || bannerConfig.visibleIf.length < 1);
};

const shouldBannerShwonIfDurationLessThanMinDuration = (ibeStateFilters, offerSpecification, bannerConfig) => {

    let ibeSearchDuration = ibeStateFilters.duration || false;
    const ibeSearchStartDate = ibeStateFilters.startDate || false;
    const ibeSearchEndDate = ibeStateFilters.endDate || false;
    const offerSpecificationMinDuration = offerSpecification.minimumDuration || false;

    // check for show due to min duration case
    if (bannerConfig.visibleIf.indexOf('durationLessThanMinDuration') !== -1 && offerSpecificationMinDuration) {
        // ignore duration if we can't map travel period

        if (ibeSearchDuration && ibeSearchStartDate && ibeSearchEndDate) {
            //we need to map duration
            const mappedTravelPeriod = ibeMappingService.mapIBETravelPeriod(ibeSearchDuration, ibeSearchStartDate, ibeSearchEndDate);
            ibeSearchDuration = mappedTravelPeriod.durations[mappedTravelPeriod.durations.length - 1];
            return ibeSearchDuration < offerSpecificationMinDuration;
        }
    }
};

const areDatesOutsideOfCampaignOfferRange = (ibeStateFilters, offerSpecification, bannerConfig) => {
    const searchDatesOutsideCampaignDates = isTravelPeriodOutsideCampaignTravelPeriod(ibeStateFilters, offerSpecification);
    // check for dates outside campaign offer range case
    return (bannerConfig.visibleIf.indexOf('searchDatesOutsideCampaignDates') !== -1 && searchDatesOutsideCampaignDates);
};

const areSearchDatesIntersectCampaignDates = (ibeStateFilters, offerSpecification, bannerConfig) => {
    if (invalidTravelPeriodCheckParameters(ibeStateFilters, offerSpecification)) return false;
    
    const travelPeriodStart = ibeStateFilters.startDate.getTime();
    const travelPeriodEnd = ibeStateFilters.endDate.getTime();
    const campaignTravelStartDate = new Date(offerSpecification.startDate).getTime();
    const campaignTravelEndDate = new Date(offerSpecification.endDate).getTime();
    const hasFlag = bannerConfig.visibleIf.indexOf('searchDatesIntersectCampaignDates') !== -1;
    const hasOverlap = hasDateOverlap(travelPeriodStart, travelPeriodEnd, campaignTravelStartDate, campaignTravelEndDate);
    
    return hasFlag && hasOverlap;
};

const isBannerShown = (ibeStateFilters, bannerConfig, offerSpecification) => {
    // no match for any case, don't show banner
    return shouldBannerAlwaysBeShwon(bannerConfig) ||
        shouldBannerShwonIfDurationLessThanMinDuration(ibeStateFilters, offerSpecification, bannerConfig) ||
        areDatesOutsideOfCampaignOfferRange(ibeStateFilters, offerSpecification, bannerConfig) ||
        areSearchDatesIntersectCampaignDates(ibeStateFilters, offerSpecification, bannerConfig);
};

const generateLandingPageLink = (platformHost, landingpageURL, campaignName, contentId) => {
    const url = new URL(landingpageURL, platformHost);
    url.search = 'campaign=' + campaignName + '&contentid=' + contentId;
    return url.toString();
};

const buildCompleteBannerConfig = (campaignConfiguration, bannerConfiguration) => {
    const configurationForGivenBannerType = bannerConfiguration.configurationForGivenBannerType;
    const bagVariant = bannerConfiguration.bagVariant;

    if (!configurationForGivenBannerType.configuration) {
        return null;
    } else {
        const campaignName = campaignConfiguration.campaignName;
        const priority = campaignConfiguration.campaignConfiguration.priority || 1;
        const configuration = configurationForGivenBannerType.configuration;
        const visibleFor = configurationForGivenBannerType.visibleFor || '';
        const visibleIf = configurationForGivenBannerType.visibleIf || [];
        const shouldResetInLink = configurationForGivenBannerType.shouldResetInLink || [];
        const variant = configuration.variant || 'default';
        const clickTarget = configuration.clickTarget || 'blank';
        const bannerId = generateBannerId(campaignName, configurationForGivenBannerType, bannerConfiguration, visibleFor);
        const contentId = typeof configurationForGivenBannerType.bannerId === 'string' && configurationForGivenBannerType.bannerId.length > 0 ?
            campaignName + '_' + configurationForGivenBannerType.bannerId : bannerId;
        const targetPageName = configurationForGivenBannerType.targetPageName;

        let resultConfig = {};

        const overrideValues = {
            campaignName,
            priority,
            bannerId,
            targetPageName,
            bagVariant,
            variant,
            clickTarget,
            shouldResetInLink,
            visibleIf,
            contentId
        };

        resultConfig = Object.assign(resultConfig, configuration, overrideValues);

        return resultConfig;
    }
};

const isTravelPeriodWithinCampaignTravelPeriod = (travelPeriod, campaignTravelPeriod) => {

    if (invalidTravelPeriodCheckParameters(travelPeriod, campaignTravelPeriod))
        return false;

    let campaignTravelStartDate = new Date(campaignTravelPeriod.startDate);
    let campaignTravelEndDate = new Date(campaignTravelPeriod.endDate);

    return (travelPeriod.startDate.getTime() >= campaignTravelStartDate.getTime() &&
        travelPeriod.endDate.getTime() <= campaignTravelEndDate.getTime());
};

const mapShorthandSearchScopePageNumber = (shorthand) => {

    if (!shorthand || typeof shorthand !== 'string' || shorthand === '') {
        return false;
    }

    let searchScopeArray = shorthand.match(/[a-zA-Z]+/g);
    let stepNumberArray = shorthand.match(/\d/) || false;
    let shortHandSearchScope;
    let searchScope;
    let stepNumber = '';

    for(let i = 0; i < searchScopeArray.length; i++) {
        shortHandSearchScope = searchScopeArray[i];
    }

    if(stepNumberArray) {
        for (let i = 0; i < stepNumberArray.length; i++) {
            stepNumber = stepNumberArray[i];
            stepNumber = parseInt(stepNumber);
        }
    }

    switch(shortHandSearchScope) {
        case 'PT' : searchScope = 'PACKAGE';
            break;
        case 'H' : searchScope = 'HOTEL';
            break;
        default: searchScope = 'PACKAGE';
    }

    return {
        searchScope,
        stepNumber
    };
};
/**
 * @param  {[type]} ibeState   [description]
 * @param  {int} stepNumber the number of the page in the funnel to generate a url for (2,3 or 4)
 * @return {Object}            the destination object to be used for IBE-Url generation
 */
const createDestinationObjectFromIBEState = (ibeState, stepNumber) => {

    let extractHotel = () => {

        if (ibeState.hotel && ibeState.hotel.giata && ibeState.hotel.name) {
            return {
                giataId: ibeState.hotel.giata,
                name: ibeState.hotel.name
            };
        }
        return false;
    };

    let extractRegion = () => {
        if (ibeState.region && ibeState.region.id && ibeState.region.name) {

            return {
                name: ibeState.region.name,
                regionId: ibeState.region.id
            }
        }
        return false;
    };

    let extractCountry = () => {
        if (ibeState.country && ibeState.country.id && ibeState.country.name) {
            return {
                name: ibeState.country.name,
                countryId: ibeState.country.id,
            }
        }
        return false;
    };

    if (stepNumber === 2) {
        return extractCountry();
    } else if (stepNumber === 3) {
        return extractRegion();
    } else if (stepNumber === 4) {
        return extractHotel();
    } else {
        // no concrete stepNumber given, find most precise match
        let destination = extractHotel();

        if (!destination) {
            destination = extractRegion();
        }

        if (!destination) {
            destination = extractCountry();
        }

        return destination;
    }
};

const isValidCampaignDuration = (searchPeriod, campaignOffersSpecification) => {

    if(!searchPeriod || !searchPeriod.startDate || !searchPeriod.endDate ||
        !campaignOffersSpecification || typeof campaignOffersSpecification.minimumDuration !== 'number') {
        return false;
    }
    let searchTravelPeriodData = ibeMappingService.mapIBETravelPeriod(searchPeriod.duration, searchPeriod.startDate, searchPeriod.endDate);

    if (searchTravelPeriodData && searchTravelPeriodData.durations) {
        let searchDurations = searchTravelPeriodData.durations;

        let searchMaxDuration = searchDurations.length !== 0 ? searchDurations[searchDurations.length - 1] : 1;

        if (searchMaxDuration >= campaignOffersSpecification.minimumDuration) {
            return true;
        }
    }

    return false;
};

const hasChild = (ibeTravellers) => {
    return ibeTravellers.children ? ibeTravellers.children && ibeTravellers.children.length > 0 : false;
};

const getAllIbeChildrenAges = (children, defaultChildAge) => {
    const foundChildAgeSmallerEqualDefaultAge = children.find((element) => {
        return element <= defaultChildAge;
    });

    if (!foundChildAgeSmallerEqualDefaultAge) {
        let allChildren = [];
        allChildren.push(defaultChildAge);
        return allChildren
    }

    return children;
};

const getValidCampaignTravellers = (ibeTravellers, defaultSearchTravellers) => {
    let defaultSearchAdults;
    let defaultSearchChildrenAge;
    let ibeSearchChildrenAge;

    for(let defaultIndex = 0; defaultIndex < defaultSearchTravellers.length; defaultIndex++) {
        let travellersByIndex = defaultSearchTravellers[defaultIndex];
        defaultSearchAdults = travellersByIndex.adults;
        defaultSearchChildrenAge = hasChild(travellersByIndex) ? travellersByIndex.children[defaultIndex] : [];
    }

    let travellers = ibeTravellers[0];
    let ibeAdults = travellers.adults;
    ibeSearchChildrenAge = hasChild(travellers) ?
        getAllIbeChildrenAges(travellers.children, defaultSearchChildrenAge) :
        (typeof defaultSearchChildrenAge === 'number' ?
            [defaultSearchChildrenAge] : []);

    return [{
        adults: (ibeAdults < defaultSearchAdults) ? defaultSearchAdults : ibeAdults,
        children: ibeSearchChildrenAge
    }];
};

const resetDataInLink = (matchingCampaignConfiguration, ibeState, bannerConfig) => {

    let defaultSearchParams = matchingCampaignConfiguration.campaignConfiguration.defaultSearchParams ? matchingCampaignConfiguration.campaignConfiguration.defaultSearchParams[0] : false;
    let startDate = defaultSearchParams.startDate || false;
    let endDate = defaultSearchParams.endDate || false;
    let duration = defaultSearchParams.duration || false;
    let offersSpecification = matchingCampaignConfiguration.campaignConfiguration.offersSpecification;
    let ibeFilterState = ibeState.filters;
    let contentId = bannerConfig.contentId;

    let toBeSetFilters = {
        campaign: matchingCampaignConfiguration.campaignName
    };

    if (defaultSearchParams) {
        if (isValueContainedInList(bannerConfig.shouldResetInLink, "dates") &&
            !isTravelPeriodWithinCampaignTravelPeriod(ibeFilterState, offersSpecification)) {
            toBeSetFilters.startDate = startDate;
            toBeSetFilters.endDate = endDate;
        } else {
            toBeSetFilters.startDate = ibeFilterState.startDate;
            toBeSetFilters.endDate = ibeFilterState.endDate;
        }

        if (isValueContainedInList(bannerConfig.shouldResetInLink, "duration") &&
            !isValidCampaignDuration(ibeFilterState, offersSpecification)) {
            toBeSetFilters.duration = duration;
        } else {
            toBeSetFilters.duration = ibeFilterState.duration;
        }

        if (isValueContainedInList(bannerConfig.shouldResetInLink, "travellers")) {
            const ibeTravellers = ibeFilterState.travellers;
            const defaultSearchTravelers = defaultSearchParams.travellers || false;
            toBeSetFilters.travellers = getValidCampaignTravellers(ibeTravellers, defaultSearchTravelers);
        }
    }

    const targetConfig = mapShorthandSearchScopePageNumber(bannerConfig.targetPageName);
    if (!targetConfig) {
        return;
    }

    toBeSetFilters.searchScope = targetConfig.searchScope;

    let destinationObj = createDestinationObjectFromIBEState(ibeState, targetConfig.stepNumber);

    if (!destinationObj) {
        return;
    }

    return {
        toBeSetFilters,
        destinationObj,
        contentId
    };
};

const getBannerClickConfig = (bannerConfig, generateLandingPageLink, targetUrl) => {

    if( !bannerConfig || typeof bannerConfig.targetPageName !== 'string' || bannerConfig.targetPageName.length === 0)  {
        return false;
    }
    const isRelative = isRelativeUrl(bannerConfig.targetPageName);
    if ( isRelative  && (!generateLandingPageLink || typeof generateLandingPageLink !== 'function') ) {
        return false;
    }

    return {
        target: bannerConfig.clickTarget,
        targetUrl: isRelative ? generateLandingPageLink() : targetUrl
    };
};

const isRelativeUrl = (targetPageName) => {

    if(!targetPageName || typeof targetPageName !== 'string' || targetPageName === '') {
        return false;
    }

    return targetPageName.charAt(0) === '/';
};

const createBannerData = (bannerClickConfig, eventScope, element, bannerConfig) => {

    if(!bannerConfig || typeof bannerConfig !== 'object' || Object.keys(bannerConfig).length < 1 ||
        (bannerConfig.targetUrl  && typeof bannerConfig.targetUrl !== 'string' )) {
        return false;
    }

    let bannerData = {
        bannerId: bannerConfig.bannerId,
        variant: bannerConfig.variant && bannerConfig.variant !== '' ? bannerConfig.variant : 'default',
        text: bannerConfig.text,
        title: bannerConfig.title,
        buttonText: bannerConfig.buttonText,
        infoText: bannerConfig.infoText,
        background: bannerConfig.background,
        bagVariant: bannerConfig.bagVariant,
        targetUrl: bannerClickConfig.targetUrl || bannerConfig.targetPageName,
        clickTarget: bannerClickConfig.target,
        campaignName: eventScope,
        eventScope,
        element
    };

    if (bannerConfig.infoTopic) {
        bannerData.infoTopic = bannerConfig.infoTopic
    }

    return bannerData;
};

const prioritySortFunction = (firstConfigToCompare, secondConfigToCompare) => {
    return firstConfigToCompare.campaignConfiguration.priority - secondConfigToCompare.campaignConfiguration.priority
}

const prioritySeoSortFunction = (firstConfigToCompare, secondConfigToCompare) => {
    return firstConfigToCompare.priority - secondConfigToCompare.priority
}

const getPthBannerDataList = (ibeState, element, campaignConfigurations, language, platformHost, generateUrl) => {

    const isNotValid = !ibeState || !(ibeState instanceof Object) || Object.keys(ibeState).length === 0 || Array.isArray(ibeState) ||
        !ibeState.filters || !(ibeState.filters instanceof Object) || Object.keys(ibeState.filters).length === 0 ||
        !element || !(element instanceof Object) /*|| Object.keys(element).length === 0*/ || Array.isArray(element) ||
        !element.id || typeof element.id !== 'string' ||
        !element.dataset.searchScope || typeof element.dataset.searchScope !== 'string' ||
        !element.dataset.pageNumber || typeof element.dataset.pageNumber !== 'string' ||
        !Array.isArray(campaignConfigurations) || campaignConfigurations.length === 0 ||
        !language || typeof language !== 'string' ||
        !platformHost || typeof platformHost !== 'string';

    if (isNotValid) {
        return [];
    }

    const bannerDataset = element.dataset;
    const matchingCampaignConfigurations =
        campaignConfigurations
            .filter(notManuallyTurnedOff)
            .filter(hasNeededProperties)
            .filter((campaignConfiguration) => campaignConfiguration.campaignConfiguration);
    if (matchingCampaignConfigurations.length === 0) {
        return [];
    }

    const searchType = bannerDataset.searchScope;
    const pageNumber = bannerDataset.pageNumber;
    const destinationId = bannerDataset.regionId || getDestinationId(ibeState);
    const bannerPosition = getBannerPosition(element);
    
    return matchingCampaignConfigurations
        .sort(prioritySortFunction)
        .map((matchingCampaignConfiguration) => {

            const languageFiles = matchingCampaignConfiguration.campaignConfiguration.languages || false;
            const campaignName = matchingCampaignConfiguration.campaignName;

            let bannerConfig = false;

            if (bannerPosition) {
                const destinations = matchingCampaignConfiguration.campaignConfiguration.destinations || false;
                const hotels = matchingCampaignConfiguration.campaignConfiguration.hotels || false;
                const stringForAttendingDestination = getStringForAttendingDestination(destinations, destinationId, searchType);
                const stringForAttendingHotel = getStringForAttendingHotel(bannerDataset, hotels);

                const bannerConfiguration = findMatchingBannerConfiguration(languageFiles, language, searchType, pageNumber, bannerPosition, stringForAttendingDestination, stringForAttendingHotel);
                if (!bannerConfiguration) {
                    return false;
                }

                //At the moment we can only have one not self handled banner on the page
                bannerConfig = buildCompleteBannerConfig(matchingCampaignConfiguration, bannerConfiguration);
            }

            const offerSpecification = matchingCampaignConfiguration.campaignConfiguration.offersSpecification || false;
            const isShown = isBannerShown(ibeState.filters, bannerConfig, offerSpecification);

            let bannerData;
            if (bannerConfig && bannerConfig.targetPageName && isShown) {

                const resetData = resetDataInLink(matchingCampaignConfiguration, ibeState, bannerConfig);
                let targetUrl= '';

                if (!resetData) {
                    targetUrl = generateLandingPageLink(platformHost, bannerConfig.targetPageName, campaignName, bannerConfig.contentId);
                } else {
                    targetUrl = generateUrl(resetData, bannerConfig.contentId);
                }

                const bannerClickConfig = getBannerClickConfig(bannerConfig,
                    generateLandingPageLink.bind(this, platformHost, bannerConfig.targetPageName, campaignName, bannerConfig.contentId),
                    targetUrl
                );

                const eventScope = getEventScope(matchingCampaignConfiguration);
                bannerData = createBannerData(bannerClickConfig, eventScope, element, bannerConfig);
            }

            if (!bannerData) {
                return false;
            }

            return {
                campaignName,
                position: bannerPosition,
                data: bannerData
            };
        }).filter(data => data);
};

/**
 *
 * @param path
 * @param seoPages
 * @returns {boolean}
 */
const detectSeoPage = (path, seoPages) => {
    if (typeof path === 'string' && seoPages && Array.isArray(seoPages) && seoPages.length > 0) {

        // remove first and last / of the path
        let regExp = /^\/|\/$/g;
        path = path.replace(regExp, '');

        for (let i = 0; i < seoPages.length; i++) {
            let replacedSeoPages = seoPages[i].replace(regExp, '');
            if (replacedSeoPages === path) {
                return true;
            }
        }
    }

    return false;
};

const getSeoBannerData = (seoPageConfigurations, language, platformHost) => {
    if(!seoPageConfigurations || !Array.isArray(seoPageConfigurations) || seoPageConfigurations.length === 0 ||
       !language || typeof language !== 'string') {
        return null;
    }

    let filterSeoPageConfigs =  seoPageConfigurations.map((campaign) => {
        const campaignLanguages = campaign.campaignConfiguration.languages;
        if (campaignLanguages) {
            const foundBannerConfiguration = findMatchingBannerConfiguration(campaignLanguages, language, 'SEO', 'SEO', 'below-compact-search');
            if (foundBannerConfiguration) {
                const bannerConfig = foundBannerConfiguration.configurationForGivenBannerType.configuration;
                const targetUrl = new URL(foundBannerConfiguration.configurationForGivenBannerType.targetPageName, platformHost);
                const bannerId = foundBannerConfiguration.configurationForGivenBannerType.bannerId || `${campaign.campaignName}_SEO_SEO_below-compact-search`;
                return {
                    campaignName: campaign.campaignName,
                    bannerId,
                    priority: campaign.campaignConfiguration.priority,
                    targetUrl: targetUrl.href,
                    clickTarget: bannerConfig.clickTarget,
                    variant: bannerConfig.variant,
                    text: bannerConfig.text,
                    title: bannerConfig.title,
                    buttonText: bannerConfig.buttonText,
                    infoText: bannerConfig.infoText,
                    background: bannerConfig.background,
                    bagVariant: foundBannerConfiguration.bagVariant
                };
            }
        }
    }).filter(data => data);

    return filterSeoPageConfigs.sort(prioritySeoSortFunction)[0] || null;
};

const appendClickTargetToBannerData = (bannerData, platformHost) => {

    if (!bannerData || typeof bannerData !== 'object' || Object.keys(bannerData).length < 1 ||
        !platformHost || typeof platformHost !== 'string') {
        return {};
    }

    bannerData.targetUrl = generateLandingPageLink(platformHost, bannerData.targetPageName, bannerData.campaignName, bannerData.contentId);
    return bannerData;
};

const removeBanner = (bannerData, id, element) => {
    if (!bannerData) { return; }
    const bannerId = bannerData.bannerId;
    const searchScope = element ? element.parentNode : document;
    const banner = searchScope.querySelector('tui-banner[data-banner-id="' + bannerId + '"]');
    if (banner) {
        elchspuckeService.removeNode(banner);
    }
}

const injectBannerTag = (bannerData, id, element) => {
   
    const bannerTag = BannerService.getBannerTag(bannerData, id);
    if (!bannerTag) { return; }

    bannerTag.addEventListener('ctaClick', () => {
        elchspuckeService.trackEvent('feature', bannerData.campaignName, bannerData.bannerId + '_banner_was_clicked');
        if (bannerData.targetUrl && bannerData.clickTarget) {
            if (bannerData.clickTarget !== 'self') {
                window.open(bannerData.targetUrl);
            } else {
                window.location.assign(bannerData.targetUrl);
            }
        }
    });

    if (id === 'tui-campaign-banner-seo') {
        elchspuckeService.insertAfter(bannerTag, element);
    } else {
        element.appendChild(bannerTag);
    }
};


const isCampaignActive = function(campaign) {
    var now = new Date();
    var fakeNow = elchspuckeService.getParameterByName('campaigns-fakedServiceTime');
    if (fakeNow) {
        now = new Date(fakeNow);
    }
    if (campaign &&
        campaign.campaignConfiguration &&
        campaign.campaignConfiguration.active === true &&
        campaign.campaignConfiguration.campaignPeriod &&
        campaign.campaignConfiguration.campaignPeriod.endDate && campaign.campaignConfiguration.campaignPeriod.startDate &&
        now < new Date(campaign.campaignConfiguration.campaignPeriod.endDate) && now > new Date(campaign.campaignConfiguration.campaignPeriod.startDate)) {
        return true;
    }
    return false;
};

const injectCampaignCardsIntoPTH2 = function (tenant, ibeSearchResultData, ibeState, campaigns) {

    if (ibeSearchResultData && ibeSearchResultData.response && ibeSearchResultData.response.currentRegion)
        var countryId = ibeSearchResultData.response.currentRegion.countryId;
    if (countryId && typeof countryId === 'number' && campaigns) {
        var hotels = [];

        var isACampaignActive = false;
        for (var i=0; i < campaigns.length; i++) {
            if (isCampaignActive(campaigns[0])) {
                isACampaignActive = true;
                break;
            }
        }
        if (isACampaignActive) {
            campaigns.forEach(function (campaign) {
                if (isCampaignActive(campaign)) {
                    var campaignConfiguration = campaign.campaignConfiguration;

                    if (campaignConfiguration.hotels && Array.isArray(campaignConfiguration.hotels)) {
                        var campaignHotels = campaignConfiguration.hotels.filter(function (hotel) {
                            return hotel.countryId === countryId;
                        });
                        hotels = hotels.concat(campaignHotels);
                    }
                }
            });
            var filterGiataCodes = [];
            if (hotels.length > 0) { //there are hotels in this country that belong to the campaign => show the cards
                filterGiataCodes = hotels.map(hotel => hotel.giataId);
            } else if (hotels.length === 0 && countryId === 1327) { //beliebteste Reiseziele
                filterGiataCodes = [3122,840,134468,53100,365325,6648,780,15193,3654,2779,425030,28463,13568,231754,20465,28019,20404,39513,40156];
            }
            if (filterGiataCodes.length > 0) {
                var eventScope = 'pt2CampaignCards';
                var targetContainer = document.querySelector('#tui-campaign-banner-search-result-list-bottom');
                var oldCampaignCards = document.querySelector('[data-event-scope=' + eventScope + ']');
                if (oldCampaignCards) {
                    targetContainer.removeChild(oldCampaignCards.parentNode);
                }

                const div = elchspuckeService.insertFluidContainerBefore(targetContainer);

                const filter = ibeState.filters;
                const searchParameters = {
                    searchScope: filter.searchScope,
                    startDate: formatDate(filter.startDate),
                    endDate: formatDate(filter.endDate),
                    airports: filter.departureAirports,
                    duration: elchspuckeService.goAndHandlePTH4Durations(filter.duration, filter.startDate, filter.endDate),
                    giatas: filterGiataCodes, 
                    resultsTotal: 20
                };
                const pageName = elchspuckeService.getPageName();
                HotelOfferCardsService.injectOrUpdateCards(div, null,
                    eventScope, tenant, 'medium', searchParameters,
                    cards => {
                        cards.setAttribute('show-cards-carousel', 'true')
                        cards.setAttribute('static-url-parameters', JSON.stringify({ contentid: `HOTELCARD_${pageName}` }));
                    });
            }
        }
    }
};

export {
    getPthBannerDataList,
    getEventScopes,
    detectSeoPage,
    getSeoBannerData,
    appendClickTargetToBannerData,
    removeBanner,
    injectBannerTag,
    injectCampaignCardsIntoPTH2
}
