import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service';
import {getPageLanguage} from '../../elchspucke-tuicom.service';
//import * as linkBuilder from '../../link-builder.service';
const linkBuilder = require('../../link-builder.service').default;

import * as util from '../../util.js';

const getCardAttributesFromUtag = () => {
    const startDate = elchspuckeTuicomService.getParameterByName('startDate');
    const endDate = elchspuckeTuicomService.getParameterByName('endDate');
    const durationString = elchspuckeTuicomService.getParameterByName('duration');
    const departureAirports = elchspuckeTuicomService.getParameterByName('departureAirports');
    const travellers = elchspuckeTuicomService.getParameterByName('travellers');

    const hotelCardAttributes = {};

    if (!isNaN(Date.parse(startDate))) {
        hotelCardAttributes.searchDepartureDate = startDate;
    }

    if (!isNaN(Date.parse(endDate))) {
        hotelCardAttributes.searchReturnDate = endDate;
    }

    if (durationString) {
        var duration = [];
        if (durationString === 'exact') {
            var exactDuration = (Date.parse(endDate) - Date.parse(startDate)) / (1000 * 60 * 60 * 24);
            if (exactDuration > 0 || exactDuration <= 90) {
                duration.push(exactDuration);
            }
        } else {
            var durationArr = durationString.split('-');
            for (var index = 0; index < durationArr.length; index++) {
                if (durationArr[index].length > 0 && !isNaN(durationArr[index])) {
                    duration.push(durationArr[index]);
                }
            }
        }
        hotelCardAttributes.searchDuration = duration.map(Number);
    }

    if (!travellers) {
        hotelCardAttributes.searchAdults = '2';
    } else {
        const decodedTravellers = decodeTravellers(travellers);
        hotelCardAttributes.searchAdults = decodedTravellers.adults;
        hotelCardAttributes.searchChilds = decodedTravellers.children;
    }

    if (departureAirports) {
        hotelCardAttributes.searchDepartureAirportCodes = departureAirports.split(';');
    }

    return hotelCardAttributes;
};

const decodeTravellers = (travellers) => {
    const roomStrings = travellers.split(',');

    let adultsAndChildren;
    let adultsArray = [];
    let childrenArray = [];
    roomStrings.map((roomString) => {
        adultsAndChildren = roomString.split('-');

        adultsArray.push(adultsAndChildren[0]);
        childrenArray.push(adultsAndChildren[1]);

    });

    return {
        adults: adultsArray.join(','),
        children: childrenArray.join(',')
    }
};

const createHotelOfferCardsTag = (id, eventScope, tenant, variant) => {

    var cardsTag = document.createElement('tui-hotel-offer-cards');
    id && cardsTag.setAttribute('data-id', id);
    cardsTag.setAttribute('class', 'tui-component');
    cardsTag.setAttribute('data-stencil', 'true');
    cardsTag.setAttribute('scope', eventScope);
    cardsTag.setAttribute('tenant', tenant);
    cardsTag.setAttribute('locale', elchspuckeTuicomService.getPageLocale());
    variant && cardsTag.setAttribute('variant', variant);

    return cardsTag;
};

const mapAdultsAndChildrenToRoom = (adults, children) => {

    var rooms = [];

    if (!adults || typeof adults !== 'string' || adults.length < 1) {
        rooms.push({numberOfAdults: 2});
        return rooms;
    }

    var adultTravellers = adults.split(',');
    var childrenTravellers = (children && typeof children === 'string') ? children.split(',') : '';

    for (var i = 0; i < adultTravellers.length; i++) {
        var numberOfAdults = parseInt(adultTravellers[i], 10);

        var room = {};
        room.numberOfAdults = numberOfAdults;

        if (childrenTravellers[i]) {
            var childAges = childrenTravellers[i].split(';').map(Number);
            room.childAges = childAges;
        }

        rooms.push(room);
    }

    return rooms;
};

const getFilterData = (data) => {
    const filterData = {};
    if (data.startDate) {
        filterData.startDate = data.startDate;
    }
    if (data.endDate) {
        filterData.endDate = data.endDate;
    }
    if (data.duration.length > 0) {
        filterData.duration = data.duration[0];
        if (data.duration.length > 1) {
            filterData.duration = `${Math.min(...data.duration)}-${Math.max(...data.duration)}`;
        }
    }
    if (data.airports && data.airports.length > 0) {
        filterData.departureAirports = data.airports.join(';')
    }
    if (data.airlines && data.airlines.length > 0) {
        filterData.airlines = data.airlines.join(';')
    }
    if (data.maxStopOver >= 0) {
        filterData.maxStopOver = data.maxStopOver.toString();
    }
    if (data.departureMinTime) {
        filterData.departureMinTime = data.departureMinTime;
    }
    if (data.departureMaxTime) {
        filterData.departureMaxTime = data.departureMaxTime;
    }
    if (data.returnMinTime) {
        filterData.returnMinTime = data.returnMinTime;
    }
    if (data.returnMaxTime) {
        filterData.returnMaxTime = data.returnMaxTime;
    }
    if (data.minPrice) {
        filterData.minPrice = data.minPrice;
    }
    if (data.maxPrice) {
        filterData.maxPrice = data.maxPrice;
    }
    if (data.operators && data.operators.length > 0) {
        filterData.operators = data.operators.join(';')
    }
    if (data.searchScope) {
        filterData.searchScope = data.searchScope;
    }
    if (data.rooms && Array.isArray(data.rooms)) {
        filterData.adults = data.rooms.map(room => room.numberOfAdults).join(',');
        filterData.childs = data.rooms.map((room) => {
            if (room.childAges && Array.isArray(room.childAges)) {
                return room.childAges.join(';');
            }
            return '';
        }).join(',');
        if (data.rooms[0].boardTypes) {
            filterData.boardTypes = data.rooms[0].boardTypes.join(';');
        }
        if (data.rooms[0].roomTypes) {
            filterData.roomTypes = data.rooms[0].roomTypes.join(';');
        }
    }
    return filterData;
};

const addStaticUrlParameters = (staticUrlParameters, giataId, url) => {

    if (staticUrlParameters) {
        const parametersObject = JSON.parse(staticUrlParameters);

        if (parametersObject && typeof parametersObject === 'object') {
            for (let additionalParam in parametersObject) {
                if (additionalParam.toLowerCase() === "contentid") {
                    if (!parametersObject[additionalParam].endsWith(giataId)) {
                        parametersObject[additionalParam] += '_' + giataId;
                    }
                }
                url.searchParams.set(additionalParam, parametersObject[additionalParam]);
            }
        }
    }
};

const generateCallToActionUrls = (plattformUrl, searchParameters, hotelOffers, staticUrlParameters, callback) => {
    var urls = [];
    if (hotelOffers && searchParameters) {
        const filterData = getFilterData(searchParameters);

        for (var i = 0; i < hotelOffers.length; i++) {

            let destinationObj = {
                giataId: hotelOffers[i].hotel.giataId,
                name: hotelOffers[i].hotel.name
            };

            let urlString = linkBuilder.generateUrl(destinationObj, filterData || {});
            let targetUrl = util.cleanStringFromTrailingChar(plattformUrl, '/') + urlString;
            targetUrl += addAffiliateParameter();

            const url = new URL(targetUrl);
            addStaticUrlParameters(staticUrlParameters, destinationObj.giataId, url);

            urls.push({giataId: destinationObj.giataId, url: url.href });
        }
        callback(urls);
    }
};

// we need to keep some values for tracking, for example:
// coopid=AFF_TUI_TRIPADVISOR&utm_source=tripadvisor&utm_medium=metasearch&utm_campaign=hotelonly&utm_content=28019&utm_term=tripadvisor
const addAffiliateParameter = () => {
    var affiliateParam = '';
    affiliateParam += checkParameter('coopid');
    affiliateParam += checkParameter('utm_source');
    affiliateParam += checkParameter('utm_medium');
    affiliateParam += checkParameter('utm_campaign');
    affiliateParam += checkParameter('utm_content');
    affiliateParam += checkParameter('utm_term');
    affiliateParam += checkParameter('TAprice');
    return affiliateParam;
};

const checkParameter = (name) => {
    var value = elchspuckeTuicomService.getParameterByName(name);
    if (value) {
        return '&' + name + '=' + value;
    }
    return '';
};

const retrieveBanderoles = (campaignServicePath, tenant, searchParameters, callback) => {
    const lang = getPageLanguage();
    const { searchScope, startDate, endDate } = searchParameters;
    fetch(`${campaignServicePath}/banderoles/${tenant}/${lang}/${searchScope}/${startDate}/${endDate}`)
        .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .then((banderoles) => {
            const campaignMembers = [];
            for (const campaignName in banderoles) {
                const banderole = banderoles[campaignName];
                banderole.giataIds.forEach(giataId => {
                    campaignMembers.push({
                        giataId,
                        config: {
                            iconUrl: banderole.iconUrl,
                            label: banderole.label,
                            description: banderole.description,
                            background: banderole.backgroundColor,
                            fontColor: banderole.fontColor
                        }
                    });
                });
            }
            callback(campaignMembers);
        });
};

let priceType = 'per_person';
const updateViewSettings = (data) => {
    priceType = data.showTotalPrice ? 'total' : 'per_person';

    const cards = document.querySelectorAll('tui-hotel-offer-cards')
    cards.forEach(card => card.setAttribute('price-type', priceType))
}

const injectOrUpdateCards = (targetContainer, id, eventScope, tenant, variant, searchParameters, configure) => {
    let cards = id && targetContainer.querySelector(`tui-hotel-offer-cards[data-id=${id}]`);
    if (!cards) {
        cards = createHotelOfferCardsTag(id, eventScope, tenant, variant);
        targetContainer.appendChild(cards);
        window.tuiCottonBall.broadcast("cotton-ball", "New TUI components.", {});
    }

    if (configure) {
        configure(cards);
    }

    const utagAttributes = getCardAttributesFromUtag();
    cards.setAttribute('price-type', priceType);

    const parameters = Object.assign({}, {
        startDate: utagAttributes.searchDepartureDate,
        endDate: utagAttributes.searchReturnDate,
        duration: utagAttributes.searchDuration,
        rooms: mapAdultsAndChildrenToRoom(utagAttributes.searchAdults, utagAttributes.searchChilds),
        airports: utagAttributes.searchDepartureAirportCodes,
    }, searchParameters);

    window.tuiCottonBall.publish('hotel-offer-cards', eventScope, 'search.updated', { parameters });
}

export {
    generateCallToActionUrls,
    retrieveBanderoles,
    updateViewSettings,
    injectOrUpdateCards
}
