import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service';
import * as HotelOfferCardsService from "../../hotel-offer-cards/1.6.0/service";
import { formatDate } from '../../util';

const makeid = (length) => {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
};

const callCrystalBall = (url, callback) => {
    var xmlhttp;
    xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
            var data = JSON.parse(xmlhttp.responseText);
            if (data) {
                callback(data);
            }
        }
    };
    xmlhttp.open("GET", url, true);
    xmlhttp.setRequestHeader('X-TUI-RequestId', 'myRequestId');
    xmlhttp.send();
};

const createCrystalBallCardsHeader = () => {
    var crystalCardHeaderTextMobile = document.createTextNode('HOTELS, DIE OFT ZUSAMMEN GESUCHT WERDEN');
    var crystalCardHeaderText = document.createTextNode('Diese Hotels könnten Ihnen auch gefallen:');
    var crystalCardHeader;

    crystalCardHeader = document.createElement('div');
    crystalCardHeader.setAttribute('id', 'crystalCardHeader');
    crystalCardHeader.style.paddingTop = '5px';
    crystalCardHeader.style.paddingBottom = '15px';
    crystalCardHeader.style.fontWeight = '600';

    var mq = window.matchMedia("(min-width: 767px)");
    if(mq.matches) {
        crystalCardHeader.appendChild(crystalCardHeaderText);
        crystalCardHeader.style.fontSize = '21px';
    } else {
        crystalCardHeader.appendChild(crystalCardHeaderTextMobile);
    }

    return crystalCardHeader;
};

const createCrystalCards = (hotelCardsId, ibeContainer, ibeState, giataIds, tenant) => {

    const crystalCardsContainer = document.createElement('div');
    crystalCardsContainer.setAttribute('id', 'crystalCardsContainer');
    elchspuckeTuicomService.insertAfter(crystalCardsContainer, ibeContainer);

    const separator = document.createElement("hr");
    separator.setAttribute("id", "crystalSeparator");
    crystalCardsContainer.appendChild(separator);
    crystalCardsContainer.appendChild(createCrystalBallCardsHeader());

    const filter = ibeState.filters;
    const searchParameters = {
        searchScope: filter.searchScope,
        startDate: formatDate(filter.startDate),
        endDate: formatDate(filter.endDate),
        airports: filter.departureAirports,
        duration: elchspuckeTuicomService.goAndHandlePTH4Durations(filter.duration, filter.startDate, filter.endDate),
        giatas: giataIds, 
        resultsTotal: giataIds.length
    };

    const pageName = elchspuckeTuicomService.getPageName();
    HotelOfferCardsService.injectOrUpdateCards(crystalCardsContainer, hotelCardsId,
        "crystalCards", tenant, null, searchParameters,
        cards => {
            cards.setAttribute('show-image-carousel', 'true');
            cards.setAttribute('show-cards-carousel', 'true')
            cards.setAttribute('static-url-parameters', JSON.stringify({ contentid: `HC_RECOMMENDATIONS_${pageName}` }));
        });
};

const removeRecommendations = () => {
    const crystalCardsContainer = document.getElementById('crystalCardsContainer');
    if (crystalCardsContainer) {
        elchspuckeTuicomService.removeNode(crystalCardsContainer);
    }
};

const injectRecommendations = (awsApiUrl, ibeSearchResultData, ibeState, tenant) => {
    if (ibeSearchResultData && ibeSearchResultData.data && ibeSearchResultData.data.hotel && ibeSearchResultData.data.hotel.giataId) {
        const hotelCardsId = "crystalCards" + makeid(10);
        elchspuckeTuicomService.insertIbeLastSeenContainer()
        let container = document.querySelector("app-ibe").lastChild;
        if (ibeSearchResultData && ibeSearchResultData.response && ibeSearchResultData.response.offers && ibeSearchResultData.response.offers.length === 0) {
            container = document.getElementById("globalMessages") || container;
        }
        if (container) {
            callCrystalBall(`${awsApiUrl}/crystal-tracker-service/current/hotelSuggestions/TUICOM/${ibeSearchResultData.data.hotel.giataId}`, function (giataIds) {
                if (giataIds instanceof Array && giataIds.length > 0) {
                    removeRecommendations();
                    createCrystalCards(hotelCardsId, container, ibeState, giataIds, tenant);
                }
            });
        }
    }
};

function getTravellerType() {
    if (window.utag_data['va.badges.6689'] && !window.utag_data['va.badges.7273'] && !window.utag_data['va.badges.6728']) {
        return 'SINGLE';
    } else if (!window.utag_data['va.badges.6689'] && window.utag_data['va.badges.7273'] && !window.utag_data['va.badges.6728']) {
        return 'COUPLE';
    } else if (!window.utag_data['va.badges.6689'] && !window.utag_data['va.badges.7273'] && window.utag_data['va.badges.6728']) {
        return 'FAMILY';
    }
    return undefined;
}

function getCrystalBallParameter(data) {
    var params = [];
    for(var prop in data) {
        if (data[prop]) {
            params.push(prop + '=' + data[prop]);
        }
    }
    return params;
}

var supports_local_storage = function() {
    try {
        return 'localStorage' in window && window['localStorage'] !== null;
    } catch(e){
        return false;
    }
};

var getItemFromLocalStorage = function(key) {
    if (supports_local_storage()) {
        return window.localStorage.getItem(key);
    }
    return false;

};

function getCrystalBallContentId(data) {
    var contentId = 'HC_CB_' + data.type;
    var pageId = elchspuckeTuicomService.getPageId();
    if (pageId) {
        contentId = pageId + '_' + contentId;
    }
    if (data.properties.travellerType) {
        contentId = contentId + '_' + data.properties.travellerType;
    }
    return contentId;
}

function getCrystalCardsSearchParameters(searchScope, travellerType, giataIds) {
    var rooms = [{numberOfAdults: 2, childAges: []}];
    var filters = getItemFromLocalStorage('filters');
    var parsedFilterObject = JSON.parse(filters);

    if (filters && parsedFilterObject && parsedFilterObject.storage) {
        var searchParams = parsedFilterObject.storage;

        if (searchParams.travellers && searchParams.travellers.length > 0) {
            rooms = searchParams.travellers.map(el => {
                return {numberOfAdults: el.adults, childAges: el.children};
            });
        }
    }

    let startDate = new Date();
    startDate.setDate(startDate.getDate() + 7);
    let endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 3);

    const result = {
        searchScope: searchScope,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        duration: [7],
        rooms: rooms,
        sortingOrder: 'qualifier24DESC',
        giatas: giataIds.length ? giataIds : [],
        resultsTotal: 6
    };

    if ('SINGLE' === travellerType) {
        result.rooms = [{numberOfAdults: 1, childAges: []}];
    } else if ('COUPLE' === travellerType) {
        result.rooms = [{numberOfAdults: 2, childAges: []}];
    }

    return result
}

function injectCrystalBallTopHotels(awsApiUrl, headerDiv, data) {
    var params = getCrystalBallParameter(data.properties);
    if (headerDiv !== null) {
        var urlParams = params.length > 0 ? '?' + params.join('&') : '';
        callCrystalBall(`${awsApiUrl}/crystal-tracker-service/current/topHotels/TUICOM${urlParams}`, function (response) {
            if (response.giataIds.length > 0) {

                const existingCards = document.querySelector('div [data-id="' + data.headerId + '"]');
                let div = existingCards && existingCards.parentNode;
                if (!div) {
                    div = elchspuckeTuicomService.insertFluidContainerAfter(headerDiv);
                }

                const searchParameters = getCrystalCardsSearchParameters(
                    data.properties.searchScope,
                    data.properties.travellerType,
                    response.giataIds);

                HotelOfferCardsService.injectOrUpdateCards(div, data.headerId,
                    data.headerId, 'TUICOM', data.properties.cardType, searchParameters,
                    cards => {
                        cards.setAttribute('show-cards-carousel', 'true')
                        cards.setAttribute('show-recommendations', 'false')
                        cards.setAttribute('static-url-parameters', JSON.stringify({ contentid: getCrystalBallContentId(data) }));
                    });
            }
        });
    }
}

function loadTopHotelsHotelcard(awsApiUrl, data) {
    if (!data.properties.travellerType) {
        data.properties.travellerType = getTravellerType();
    }

    var headerDoc = document.querySelector('#' + data.headerId);
    if (headerDoc) {
        if ('SINGLE' === data.properties.travellerType) {
            headerDoc.innerHTML = 'Aktuell beliebt bei Singles:';
        } else if ('COUPLE' === data.properties.travellerType) {
            headerDoc.innerHTML = 'Aktuell beliebt bei Paaren:';
        } else if ('FAMILY' === data.properties.travellerType) {
            headerDoc.innerHTML = 'Aktuell beliebt bei Familien:';
        } else {
            headerDoc.innerHTML = 'Aktuell beliebte Hotels:';
        }

        injectCrystalBallTopHotels(awsApiUrl, headerDoc, data);
    }
}

function loadTopRegionHotelcard(awsApiUrl, data) {
    if (!data.properties.travellerType) {
        data.properties.travellerType = getTravellerType();
    }

    var headerDoc = document.querySelector('#' + data.headerId);
    if (headerDoc) {
        var params = getCrystalBallParameter(data.properties);
        var urlParams =  params.length>0 ? '?' + params.join('&') : '';
        callCrystalBall(`${awsApiUrl}/crystal-tracker-service/current/topRegions/TUICOM${urlParams}`, function (response) {
            if (response.regions && response.regions.length > 0) {
                var topRegionName = response.regions[0].name.charAt(0).toUpperCase() + response.regions[0].name.slice(1);
                if ('SINGLE' === data.properties.travellerType) {
                    headerDoc.innerHTML = topRegionName + ' - Das Top Pauschalreise Ziel für Singles';
                } else if ('COUPLE' === data.properties.travellerType) {
                    headerDoc.innerHTML = topRegionName + ' - Das Top Pauschalreise Ziel für Paare';
                } else if ('FAMILY' === data.properties.travellerType) {
                    headerDoc.innerHTML = topRegionName + ' - Das Top Pauschalreise Ziel für Familien';
                } else {
                    headerDoc.innerHTML = topRegionName + ' - Das Top Pauschalreise Ziel auf TUI.com';
                }

                data.properties.region = response.regions[0].name;
                injectCrystalBallTopHotels(awsApiUrl, headerDoc, data);
            }
        });
    }
}

function loadLastBookedHotelcard(awsApiUrl, data) {
    if (!data.properties.travellerType) {
        data.properties.travellerType = getTravellerType();
    }

    var headerDoc = document.querySelector('#' + data.headerId);
    if (headerDoc) {
        var params = getCrystalBallParameter(data.properties);
        var urlParams =  params.length>0 ? '?' + params.join('&') : '';
        callCrystalBall(`${awsApiUrl}/crystal-tracker-service/current/lastBooked/TUICOM${urlParams}`, function (response) {
            if (response.giataIds.length > 0) {

                if ('SINGLE' === data.properties.travellerType) {
                    headerDoc.innerHTML = 'Zuletzt von Singles gebucht:';
                } else if ('COUPLE' === data.properties.travellerType) {
                    headerDoc.innerHTML = 'Zuletzt von Paaren gebucht:';
                } else if ('FAMILY' === data.properties.travellerType) {
                    headerDoc.innerHTML = 'Zuletzt von Familien gebucht:';
                } else {
                    headerDoc.innerHTML = 'Zuletzt gebucht auf TUI.com:';
                }

                const div = elchspuckeTuicomService.insertFluidContainerAfter(headerDoc);

                const searchParameters = getCrystalCardsSearchParameters(
                    data.properties.searchScope,
                    data.properties.travellerType,
                    response.giataIds);

                HotelOfferCardsService.injectOrUpdateCards(div, data.headerId,
                    data.headerId, 'TUICOM', data.properties.cardType, searchParameters,
                    cards => {
                        cards.setAttribute('show-cards-carousel', 'true')
                        cards.setAttribute('show-recommendations', 'false')
                        cards.setAttribute('static-url-parameters', JSON.stringify({ contentid: getCrystalBallContentId(data) }));
                    });
            }
        });
    }
}

export {
    injectRecommendations,
    removeRecommendations,
    loadTopHotelsHotelcard,
    loadTopRegionHotelcard,
    loadLastBookedHotelcard
}
