import config from './config';
import { register } from '../register-components';

import dataDog from '../../src/data-dog/mojo.js';
import PolyfillService from '../../src/polyfill-service/mojo.js';
import IbeFeatureToggles from '../../src/ibe-feature-toggles/mojo.js';
import UiElements from '../../src/ui-elements/2.1.0/mojo.js';
import PriceCalendar from '../../src/price-calendar/1.0.0/mojo.js';
import HotelCards from '../../src/hotelcards/1.16.0/mojo.js';
import HotelOfferCards from '../../src/hotel-offer-cards/1.6.0/mojo.js';
import UpsellTeaser from '../../src/upsell-teaser/1.2.7/mojo.js';
import HotelBestOffer from '../../src/hotel-best-offer/1.1.0/mojo.js';
import Favorites from '../../src/favorites/2.1.2/mojo.js';
import Travelexplorer from '../../src/travelexplorer/1.0.0/mojo.js';
import RoomsPanel from '../../src/rooms-panel/1.0.0/mojo.js';
import RoomsPanel2 from '../../src/rooms-panel/2.0.1/mojo.js';
import Shopfinder from '../../src/shopfinder/current/mojo.js';
import TravelMap from '../../src/travel-map/1.0.5/mojo';
import Login from '../../src/login/1.15.1/mojo.js';
import Recommendations from '../../src/recommendations/1.0.4/mojo';
import Campaign from '../../src/campaign/2.1.4/mojo-starter.js';
import CampaignVisuals from '../../src/campaign-visuals/1.1.3/mojo.js';
import NBSO from '../../src/nbso/1.1.0/mojo.js';
import NoResults from '../../src/no-results/1.1.0/mojo.js';
import InsuranceTracking from '../../src/insurance-tracking/mojo.js';
import AutoOffer from '../../src/auto-offer/1.0.0/mojo.js';
import Seatmap from '../../src/seatmap/mojo.js';
import MobileResponsive from '../../src/mobile-responsive/mojo.js';
import SearchHistory from '../../src/search-history/0.6.2/mojo.js';
import Filter from '../../src/filter/1.0.0/mojo.js';
import FacultativeTrips from '../../src/facultative-trips/1.1.1/mojo.js';
import HistoryBack from '../../src/history-back/1.0.0/mojo.js';
import IbeLinkBuilder from '../../src/ibe-link-builder/mojo.js';
import OfferComparator from '../../src/offer-comparator/1.0.0/mojo.js';
import CrystalTracker from '../../src/crystal-tracker/1.0.0/mojo.js';
import TravelAdvice from '../../src/travel-advice/1.1.0/mojo.js';
import Utag from '../../src/utag/1.0.0/mojo.js';
import Tracking from '../../src/tracking/1.0.1/mojo';
import PaymentsTest from '../../src/payments-test/mojo.js';
//import TuiPoiHotelMap from '../../src/poi-hotel-map/1.2.3/mojo';
import TuiHotelMap from '../../src/hotel-map/1.0.0/mojo';
import smartBanner from '../../src/smartbanner/mojo.js';
import TuiSearchResultMap from '../../src/search-result-map/1.2.0/mojo';
import UnsupportedBrowserModal from '../../src/unsupported-browser-modal/mojo';
import GebecoTrips from '../../src/gebeco-trips/0.1.0/mojo';
import TuiFlightOfferList from '../../src/tui-flight-offer/1.0.0/mojo';
import MapsConsent from '../../src/maps-consent/1.0.0/mojo';

import ExitIntent from '../../src/exit-intent/3.0.2/mojo.js';
import Coupons from '../../src/coupons/1.0.9/mojo.js';
import ImageGallery from '../../src/image-gallery/1.3.4/mojo';
import SocialStats from '../../src/social-stats/1.1.1/mojo';
import Lifebuoy from '../../src/lifebuoy/0.4.0/mojo';
import Qualtrics from '../../src/qualtrics/1.1.0/mojo';

const components = {
    AutoOffer,
    dataDog,
    PolyfillService,
    IbeFeatureToggles,
    UiElements,
    PriceCalendar,
    HotelCards,
    HotelOfferCards,
    HotelBestOffer,
    UpsellTeaser,
    Favorites,
    Travelexplorer,
    RoomsPanel,
    RoomsPanel2,
    Shopfinder,
    TravelMap,
    Login,
    Recommendations,
    Campaign,
    CampaignVisuals,
    Coupons,
    ExitIntent,
    NBSO,
    NoResults,
    InsuranceTracking,
    Seatmap,
    MobileResponsive,
    SearchHistory,
    Filter,
    FacultativeTrips,
    HistoryBack,
    IbeLinkBuilder,
    OfferComparator,
    CrystalTracker,
    TravelAdvice,
    Utag,
    Tracking,
    PaymentsTest,
    //TuiPoiHotelMap,
    TuiHotelMap,
    smartBanner,
    TuiSearchResultMap,
    UnsupportedBrowserModal,
    GebecoTrips,
    TuiFlightOfferList,
    MapsConsent,
    ImageGallery,
    SocialStats,
    Lifebuoy,
    Qualtrics
};

const prodConfig = {
    ...config,
    getApiUrl: () => 'https://api.tui.com/api/',
    getStage: () => 'prod'
};

register(components, prodConfig);
